import React from "react"
import SchedeTab, { Tab } from "../../element/Tab"
import StructuredText from "../fields/StructuredText"

const DatoSezioneConTab = ({ singoliTab = [], ...props }) => singoliTab.length > 0 && <SchedeTab>
  {singoliTab.map(tab => <Tab label={tab.titoloScheda} key={tab.key}>
    <StructuredText data={tab.contenuto} />
  </Tab>)}
</SchedeTab>


export default DatoSezioneConTab