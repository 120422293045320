import React from "react"
import StructuredText from "../fields/StructuredText"

const SezioneDiPagina = ({ contenuto, linkDiAncoraggioInterno }) => {
  return <section className="relative">
    <div id={linkDiAncoraggioInterno} className="absolute -top-11"></div>
    <StructuredText data={contenuto} />
  </section>
}

export default SezioneDiPagina