import React, { useState } from "react"
import cx from "classnames"
import SyntaxHighlighter from 'react-syntax-highlighter';
import CopyIcon from "../../assets/images/icons/code/copy.svg"
import CloseIcon from "../../assets/images/icons/code/close.svg"
import MaximazeIcon from "../../assets/images/icons/code/maximize.svg"

const Code = ({ children, copyButton = false, fullScreenButton = false, language = "json", className = "" }) => {
  const rowsNumber = ["json", "xml"].indexOf(language) !== -1
  const [fullScreenOpen, setFullScreenOpen] = useState(false)

  const Code = () => <SyntaxHighlighter
    wrapLines={true}
    showLineNumbers={rowsNumber}
    className={cx(className, "text-small font-jetbrains !p-2")}
  >{children}</SyntaxHighlighter>

  return <div className={cx("c-codice relative")}>
    {copyButton && <div className="absolute top-[14px]" style={{ right: 14 + (fullScreenButton ? 36 : 0) }}>
      <CopyButton content={children} />
    </div>}
    {fullScreenButton && <div className="absolute right-[14px] top-[14px]" >
      <FullScreenButton setOpen={setFullScreenOpen} />
    </div>}
    <Code />
    {fullScreenOpen && <FullScreen content={children} />}
  </div>
}

export const CopyButton = ({ content }) => <div
  className="cursor rounded border border-cadet-grey-40 bg-white flex items-centr justify-center"
  style={{ height: 28, width: 28 }}
  onClick={() => navigator.clipboard.writeText(content)}
  onKeyUp={() => navigator.clipboard.writeText(content)}
  role="button"
  tabIndex={0}
>
  <img src={CopyIcon} alt="copy" width={16} height={16} />
</div>

export const FullScreenButton = ({ callback = () => { } }) => <div
  className="cursor rounded border border-cadet-grey-40 bg-white flex items-centr justify-center"
  style={{ height: 28, width: 28 }}
  onClick={() => callback()}
  onKeyUp={() => callback()}
  role="button"
  tabIndex={0}
>
  <img src={MaximazeIcon} alt="full screen" width={16} height={16} />
</div>

export const CloseFullScreenButton = ({ callback = () => { } }) => <div
  className="cursor rounded border border-cadet-grey-40 bg-white flex items-centr justify-center"
  style={{ height: 28, width: 28 }}
  onClick={() => callback()}
  onKeyUp={() => callback()}
  role="button"
  tabIndex={0}
>
  <img src={CloseIcon} alt="close full screen" width={16} height={16} />
</div>

const FullScreen = ({ children }) => <div>Full screen</div>

export default Code