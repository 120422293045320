import React from "react"
import TabConCodice, { TabCodice } from "../../element/TabConCodice";
import StructuredTextCode from "../fields/StructuredTextCode";
import { render } from 'datocms-structured-text-to-plain-text';


const DatoTabConCodice = ({ tab, ...props }) => {
  return <TabConCodice>
    {tab.map(code => <TabCodice label={code.nomeDelTab} plainCode={render(code.codice)} key={`tab-codice-${code.nomeDelTab}`}>
      <StructuredTextCode data={code.codice} />
    </TabCodice>)}
  </TabConCodice>;
}


export default DatoTabConCodice