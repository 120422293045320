import React from "react"
import { components } from "../configuration/Mapping"

const InlineRecord = ({ record }) => {
  const Record = components[record.__typename] ? components[record.__typename] : Error
  return <Record {...record} />
}

const Error = ({ __typename }) => {
  if (process.env.GATSBY_ACTIVE_ENV !== "production") {
    return <h2 className="bg-ucla-gold-30 text-deep-sky-blue-20">Questo blocco non esiste (tipo: {__typename})</h2>
  }
}

export default InlineRecord