import DatoBoxConCTA from "../components/BoxConCTA";
import DatoButton from "../components/Button";
import Contenuto from "../components/Contenuto";
import DatoDoppiaColonna from "../components/DoppiaColonna";
import DatoFunzionalita from "../components/Funzionalita";
import DatoGriglia from "../components/Griglia";
import DatoGrigliaCards from "../components/GrigliaCards";
import DatoImmagine from "../components/Immagine";
import DatoLineaSeparazione from "../components/LineaSeparazione";
import DatoListaConIcone from "../components/ListaConIcon";
import DatoNotifiche from "../components/Notifiche";
import DatoPaginaNonDisponibile from "../components/PaginaNonDisponibile";
import DatoSezioneConTab from "../components/SezioneConTab";
import SezioneDiPagina from "../components/SezioneDiPagina";
import DatoSpaziatura from "../components/Spaziatura";
import DatoTabConCodice from "../components/TabConCodice";
import DatoTabella from "../components/Tabella";
import DatoInlineImmagine from "../inline/InlineImmagine";

export const components = {
  "DatoCmsSpazziatura": DatoSpaziatura,
  "DatoCmsBottone": DatoButton,
  "DatoCmsSezioneDiPagina": SezioneDiPagina,
  "DatoCmsContenuto": Contenuto,
  "DatoCmsNotifiche": DatoNotifiche,
  "DatoCmsSezioneConTab": DatoSezioneConTab,
  "DatoCmsTabella": DatoTabella,
  "DatoCmsImmagine": DatoImmagine,
  "DatoCmsCodiceATab": DatoTabConCodice,
  "DatoCmsPaginaNonDisponibile": DatoPaginaNonDisponibile,
  "DatoCmsBloccoADueColonne": DatoDoppiaColonna,
  "DatoCmsLineaDiSeparazione": DatoLineaSeparazione,
  "DatoCmsBoxConCta": DatoBoxConCTA,
  "DatoCmsGrid": DatoGriglia,
  "DatoCmsFunzionalit": DatoFunzionalita,
  "DatoCmsListaConIcone": DatoListaConIcone,
  "DatoCmsGridCard": DatoGrigliaCards,
  "DatoCmsInlineImmagine": DatoInlineImmagine
}