import React from "react";
import cx from "classnames"

const InlineImmagine = ({ titolo_alt, immagine, immagine_dark, altezza_desktop, larghezza_desktop, altezza_mobile, larghezza_mobile, nascondi_in_mobile, ...props }) => {

  const altezzaDesktop = altezza_desktop ? { minHeight: altezza_desktop, height: altezza_desktop, maxHeight: altezza_desktop } : {}
  const altezzaMobile = altezza_mobile ? { minHeight: altezza_mobile, height: altezza_mobile, maxHeight: altezza_mobile } : altezzaDesktop
  const larghezzaDesktop = larghezza_desktop ? { minWidth: larghezza_desktop, width: larghezza_desktop, maxWidth: larghezza_desktop } : {}
  const larghezzaMobile = larghezza_mobile ? { minWidth: larghezza_mobile, width: larghezza_mobile, maxWidth: larghezza_mobile } : larghezzaDesktop

  return <>
    <img src={immagine.url} alt={titolo_alt} className={cx("hidden lg:inline align-text-bottom	", { "dark:hidden": immagine_dark })} style={{ ...altezzaDesktop, ...larghezzaDesktop }} />
    {!nascondi_in_mobile && <img src={immagine.url} alt={titolo_alt} className={cx("inline lg:hidden align-text-bottom	", { "dark:hidden": immagine_dark })} style={{ ...altezzaMobile, ...larghezzaMobile }} />}

    {immagine_dark && <img src={immagine_dark.url} alt={titolo_alt} className={cx("hidden dark:hidden lg:dark:inline align-text-bottom	")} style={{ ...altezzaDesktop, ...larghezzaDesktop }} />}
    {immagine_dark && !nascondi_in_mobile && <img src={immagine_dark.url} alt={titolo_alt} className={cx("hidden dark:inline lg:dark:hidden align-text-bottom	")} style={{ ...altezzaMobile, ...larghezzaMobile }} />}
  </>
}

export default InlineImmagine