import React from "react"
import cx from "classnames"
import LogoSuccess from "../../assets/images/icons/i-success.png"
import LogoWarning from "../../assets/images/icons/i-warning.png"
import LogoError from "../../assets/images/icons/i-error.png"
import LogoDefault from "../../assets/images/icons/i-default.png"
import LogoInfo from "../../assets/images/icons/i-info.png"

const Notifiche = ({ tipo = "default", children }) => {
  const type = ["info", "error", "warning", "success", "default"].includes(tipo) ? tipo : "default"
  return <div className={cx("c-notifiche notifica border py-1 pl-2 pr-2 rounded mb-2 text-small flex items-start", {
    "border-cadet-grey-60 bg-cadet-grey-70 text-cadet-grey-20": type === "info",
    "border-deep-sky-blue-60 bg-deep-sky-blue-70 text-deep-sky-blue-10": type === "default",
    "border-amaranth-60 bg-amaranth-70 text-amaranth-30": type === "error",
    "border-ucla-gold-60 bg-ucla-gold-70 !text-ucla-gold-20": type === "warning",
    "border-pastel-green-60 bg-pastel-green-70 text-pastel-green-20": type === "success"
  })}>
    {tipo === "success" && <img src={LogoSuccess} height={16} width={16} className={"max-h-2 mr-2 mt-0.5"} alt={tipo} />}
    {tipo === "warning" && <img src={LogoWarning} height={16} width={16} className={"max-h-2 mr-2 mt-0.5"} alt={tipo} />}
    {tipo === "error" && <img src={LogoError} height={16} width={16} className={"max-h-2 mr-2 mt-0.5"} alt={tipo} />}
    {tipo === "info" && <img src={LogoInfo} height={16} width={16} className={"max-h-2 mr-2 mt-0.5"} alt={tipo} />}
    {tipo === "default" && <img src={LogoDefault} height={16} width={16} className={"max-h-2 mr-2 mt-0.5"} alt={tipo} />}
    <div>{children}</div>
  </div>
}

export default Notifiche