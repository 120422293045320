import React from "react"
import GrigliaCards, { GrigliaCard } from "../../element/GrigliaCards"
import StructuredText from "../fields/StructuredText"

const DatoGrigliaCards = ({ intestazione, numeroColonne, cards, ...props }) => {
  return <section>
    <StructuredText data={intestazione} />
    <div className="mb-2" />
    <GrigliaCards cols={numeroColonne}>
      {cards.map(card => <GrigliaCard
        icon={card.icona !== null ? card.icona.url : undefined}
        link={card.link && card.link !== null ? `/${card.link.locale}/${card.link.slug}` : undefined}
      >
        {card.label}
      </GrigliaCard>)}
    </GrigliaCards>
  </section>
}

export default DatoGrigliaCards