import React from "react";
import { graphql } from "gatsby"
import Helmet from "react-helmet";
import Page from "../components/page";
import DocsLayout from "../components/layout/DocsLayout";
import ModularContent from "../components/datocms/fields/ModularContent";
import TableOfContent, { TableOfContentFromModular } from "../components/layout/components/TableOfContent";
import Feedback from "../components/element/Feedback";
import RelatedPages from "../components/element/RelatedPages";
import Spaziatura from "../components/element/Spaziatura";
import Breadcrumb from "../components/element/Breadcrumb"
import { extractText } from "../algolia/utility";

export default function DocsPageTemplate({ data, pageContext }) {
  const { seoMetaTags, contenutoPrincipale, tableOfContentVisibile, feedback, breadcrumbs, paginaSuccessiva, paginaPrecedente } = data.datoCmsPaginaDiDocumentazione

  return <Page lang={pageContext.lang}>
    <DocsLayout alternatives={pageContext.alternatives} sidebar={pageContext.sidebar[pageContext.lang]} currentPageId={pageContext.id} tableOfcontent={tableOfContentVisibile}>

      <Helmet htmlAttributes={{ lang: pageContext.lang }}>
        {customSeoFunction(seoMetaTags.tags)}
      </Helmet>

      <div className="flex">
        <div id="modular-content" className="w-full">
          <Breadcrumb links={breadcrumbs.map(link => { return { [link.label]: link.link === null ? undefined : `/${link.link.locale}/${link.link.slug}` } })} />
          <ModularContent components={contenutoPrincipale} />
          <Spaziatura desktop={15} mobile={15} />
          {/* {feedback && <Feedback />} temp. rimosso perché scollegato da SM */}
          <PagineCollegate prev={paginaPrecedente} next={paginaSuccessiva} />
        </div>
        {tableOfContentVisibile && <TableOfContent>
          <TableOfContentFromModular contenuto={contenutoPrincipale} />
        </TableOfContent>}
      </div>

      <Spaziatura desktop={20} mobile={20} />

    </DocsLayout>
  </Page >
}

const PagineCollegate = ({ next, prev }) => {
  const prevData = prev ? {
    label: prev.titolo !== null ? prev.titolo : prev.seo.title,
    link: `/${prev.locale}/${prev.slug}`
  } : undefined

  const nextData = next ? {
    label: next.titolo !== null ? next.titolo : next.seo.title,
    link: `/${next.locale}/${next.slug}`
  } : undefined

  return (prev || next) && <>
    <Spaziatura desktop={5} mobile={5} />
    <RelatedPages prev={prevData} next={nextData} />
  </>;
}

export const query = graphql`
  query paginaDiDocumentazione($id: String!) {
    datoCmsPaginaDiDocumentazione(id: {eq: $id}) {
      originalId
      feedback
      tableOfContentVisibile
      breadcrumbs {
        label
        link {
          slug
          locale
        }
      }
      paginaPrecedente {
        locale
        slug
        seo {
          title
        }
        titolo
      }
      paginaSuccessiva {
        locale
        slug
        seo {
          title
        }
        titolo
      }
      seoMetaTags {
        tags
      }
      contenutoPrincipale {
        ...DatoCmsBottoneFragment
        ...DatoCmsSpaziaturaFragment
        ...DatoCmsNotificheFragment
        ...DatoCmsTabellaFragment
        ...DatoCmsImmagineFragment
        ...DatoCmsCodiceATab
        ...DatoCmsBloccoADueColonneFragment
        ...DatoCmsLineaDiSeparazioneFragment
        ...DatoCmsBoxConCtaFragment
        ...DatoCmsGridFragment
        ...DatoCmsFunzionalitFragment
        ...DatoCmsListaConIconeFragment
        ...DatoCmsSezioneConTabFragment
        ...DatoCmsGridCardFragment
        ... on DatoCmsPaginaNonDisponibile {
          __typename
          key: id
          id: originalId
        }
        ... on DatoCmsSezioneDiPagina {
          __typename
          key: id
          titolo
          presenteSullaTableOfContent
          linkDiAncoraggioInterno
          contenuto {
            ...DatoCmsContenutoFragment
          }
        }
      }
    }
  }

  fragment DatoCmsBottoneFragment on DatoCmsBottone {
    __typename
    key: id
    id: originalId
    testo
    linkEsterno
    ancoraggioInterno
    tipologiaDiLink
    linkInterno {
      slug
      locale
    }
  }

  fragment DatoCmsSpaziaturaFragment on DatoCmsSpazziatura {
    __typename
    key: id
    id: originalId
    distanzaDesktop
    distanzaMobile
    id: originalId
  }

  fragment DatoCmsNotificheFragment on DatoCmsNotifiche {
    __typename
    key: id
    id: originalId
    tipo
    content: contenuto {
      value
      links {
        ... on DatoCmsPaginaDiDocumentazione {
          __typename
          slug
          locale
          id: originalId
        }
      }
      blocks {
        __typename
        ...DatoCmsSpaziaturaFragment
        ...DatoCmsBottoneFragment
      }
    }
  }

  fragment DatoCmsContenutoFragment on DatoCmsDatoCmsSezioneDiPaginaContenutoStructuredText {
    __typename
    value
    links {
      ... on DatoCmsPaginaDiDocumentazione {
        __typename
        slug
        locale
        id: originalId
      }
      ... on DatoCmsInlineImmagine {
        __typename
        id: originalId
        nascondiInMobile
        titoloAlt
        larghezzaDesktop
        larghezzaMobile
        altezzaDesktop
        altezzaMobile
        immagine {
          url
        }
        immagineDark {
          url
        }
      }
    }
    blocks {
      __typename
      ...DatoCmsSpaziaturaFragment
      ...DatoCmsBottoneFragment
      ...DatoCmsNotificheFragment
      ...DatoCmsImmagineFragment
      ...DatoCmsLineaDiSeparazioneFragment
      ...DatoCmsBoxConCtaFragment
      ...DatoCmsCodiceATab
    }
  }

  fragment DatoCmsImmagineFragment on DatoCmsImmagine {
    __typename
    key: id
    id: originalId
    visualizzazione
    testoAlternativo
    immagine {
      alt
      url
      gatsbyImageData(layout: CONSTRAINED)
      format
    }
    immagineFullWidth: immagine {
      alt
      url
      gatsbyImageData(layout: CONSTRAINED)
      format
    }
    immagineDark {
      alt
      url
      gatsbyImageData(layout: CONSTRAINED)
      format
    }
    immagineDarkFullWidth: immagineDark {
      alt
      url
      gatsbyImageData(layout: CONSTRAINED)
      format
    }
    immagineDarkMobile {
      alt
      url
      gatsbyImageData(layout: CONSTRAINED)
      format
    }
    immagineDarkMobileFullWidth: immagineDarkMobile {
      alt
      url
      gatsbyImageData(layout: CONSTRAINED)
      format
    }
    immagineMobile {
      alt
      url
      gatsbyImageData(layout: CONSTRAINED)
      format
    }
    immagineMobileFullWidth: immagineMobile {
      alt
      url
      gatsbyImageData(layout: CONSTRAINED)
      format
    }
  }

  fragment DatoCmsTabellaFragment on DatoCmsTabella {
    __typename
    key: id
    id: originalId
    formatoColonne
    intestazioneColonna1
    intestazioneColonna2
    intestazioneColonna3
    intestazioneColonna4
    intestazioneColonna5
    intestazioneColonna6
    righe {
      colonna1 {
        __typename
        value
        links {
          __typename
          slug
          locale
          id: originalId
        }
        blocks {
          __typename
          ...DatoCmsSpaziaturaFragment
          ...DatoCmsBottoneFragment
          ...DatoCmsNotificheFragment
        }
      }
      colonna2 {
        __typename
        value
        links {
          __typename
          slug
          locale
          id: originalId
        }
        blocks {
          __typename
          ...DatoCmsSpaziaturaFragment
          ...DatoCmsBottoneFragment
          ...DatoCmsNotificheFragment
        }
      }
      colonna3 {
        __typename
        value
        links {
          __typename
          slug
          locale
          id: originalId
        }
        blocks {
          __typename
          ...DatoCmsSpaziaturaFragment
          ...DatoCmsBottoneFragment
          ...DatoCmsNotificheFragment
        }
      }
      colonna4 {
        __typename
        value
        links {
          __typename
          slug
          locale
          id: originalId
        }
        blocks {
          __typename
          ...DatoCmsSpaziaturaFragment
          ...DatoCmsBottoneFragment
          ...DatoCmsNotificheFragment
        }
      }
      colonna5 {
        __typename
        value
        links {
          __typename
          slug
          locale
          id: originalId
        }
        blocks {
          __typename
          ...DatoCmsSpaziaturaFragment
          ...DatoCmsBottoneFragment
          ...DatoCmsNotificheFragment
        }
      }
      colonna6 {
        __typename
        value
        links {
          __typename
          slug
          locale
          id: originalId
        }
        blocks {
          __typename
          ...DatoCmsSpaziaturaFragment
          ...DatoCmsBottoneFragment
          ...DatoCmsNotificheFragment
        }
      }
    }
  }
  
  fragment DatoCmsCodiceATab on DatoCmsCodiceATab {
    ... on DatoCmsCodiceATab {
      __typename
      key: id
      id: originalId
      tab {
        codice {
          __typename
          blocks
          value
        }
        nomeDelTab
      }
    }
  }

  fragment DatoCmsBloccoADueColonneFragment on DatoCmsBloccoADueColonne {
    __typename
    key: id
    id: originalId
    grandezzaColonne
    invertiMobile
    colonna1 {
      links {
        __typename
        slug
        locale
        id: originalId
      }
      value
      blocks {
        __typename
        ...DatoCmsSpaziaturaFragment
        ...DatoCmsBottoneFragment
        ...DatoCmsNotificheFragment
        ...DatoCmsImmagineFragment
        ...DatoCmsFunzionalitFragment
      }
    }
    colonna2 {
      links {
        __typename
        slug
        locale
        id: originalId
      }
      value
      blocks {
        __typename
        ...DatoCmsSpaziaturaFragment
        ...DatoCmsBottoneFragment
        ...DatoCmsNotificheFragment
        ...DatoCmsImmagineFragment
        ...DatoCmsFunzionalitFragment
      }
    }
  }

  fragment DatoCmsLineaDiSeparazioneFragment on DatoCmsLineaDiSeparazione {
    __typename
    key: id
    id: originalId
  } 

  fragment DatoCmsBoxConCtaFragment on DatoCmsBoxConCta {
    __typename
    key: id
    id: originalId
    link
    testoCta
    linkEsterno
    linkInterno {
      locale
      slug
    }
    contenuto {
      links {
        __typename
        slug
        locale
        id: originalId
      }
      value
      blocks {
        __typename
        ...DatoCmsSpaziaturaFragment
        ...DatoCmsBottoneFragment
        ...DatoCmsLineaDiSeparazioneFragment
      }
    }
  } 

  fragment DatoCmsGridFragment on DatoCmsGrid {
    __typename
    key: id
    id: originalId
    bordoSuiBox
    numeroDiColonne
    intestazione {
      links {
        __typename
        slug
        locale
        id: originalId
      }
      value
      blocks {
        __typename
        ...DatoCmsSpaziaturaFragment
        ...DatoCmsBottoneFragment
        ...DatoCmsLineaDiSeparazioneFragment
      }
    }
    content2: contenuto {
      __typename
      key: id
      id: originalId
      content3: contenuto {
        links {
          __typename
          slug
          locale
          id: originalId
        }
        value
        blocks {
          __typename
          ...DatoCmsSpaziaturaFragment
          ...DatoCmsBottoneFragment
          ...DatoCmsLineaDiSeparazioneFragment
        }
      }
    }
  }

  fragment DatoCmsFunzionalitFragment on DatoCmsFunzionalit {
    __typename
    key: id
    id: originalId
    numeroColonne
    titolo
    bordo
    listaFunzionalit {
      attiva
      label
    }
  }

  fragment DatoCmsListaConIconeFragment on DatoCmsListaConIcone {
    __typename
    key: id
    id: originalId
    colonne
    titolo
    statoIniziale
    lista {
      label
      linkInterno {
        locale
        slug
      }
      icona {
        url
      }
    }
  }

  fragment DatoCmsSezioneConTabFragment on DatoCmsSezioneConTab {
    __typename
    key: id
    id: originalId
    singoliTab {
      __typename
      key: id
      id: originalId
      titoloScheda
      contenuto {
        blocks {
          __typename
          ...DatoCmsSpaziaturaFragment
          ...DatoCmsBottoneFragment
          ...DatoCmsNotificheFragment
          ...DatoCmsImmagineFragment
        }
        value
        links {
          __typename
          slug
          locale
          id: originalId
        }
      }
    }
  }

  fragment DatoCmsGridCardFragment on DatoCmsGridCard {
    __typename
    key: id
    id: originalId
    numeroDiColonne
    intestazione {
      links {
        __typename
        slug
        locale
        id: originalId
      }
      value
      blocks {
        __typename
        ...DatoCmsSpaziaturaFragment
        ...DatoCmsBottoneFragment
        ...DatoCmsLineaDiSeparazioneFragment
      }
    }
    cards {
      link {
        slug
        locale
      }
      label
      icona {
        url
      }
    }
  }
`

const customSeoFunction = (seo) => {
  return seo.map(field => {
    if (field.tagName === "title") {
      return <title key={"title"}>{field.content + " | Axerve"}</title>
    }

    if (field.tagName === "meta" && field.attributes && field.attributes.name && field.attributes.content) {
      return <meta key={`meta-${field.attributes.name}`} name={field.attributes.name} content={field.attributes.content} />
    }

    return null
  })
}
