import React from "react";
import cx from "classnames"

const Tabella = ({ formato, children, className = "", ...props }) => {
  if (!checkFormato(formato)) {
    return null
  }
  const formatoFinale = formato.split(" ").filter(value => value !== "" && value).join(" ")
  const classiIntestazioni = "py-1 font-medium text-cadet-grey-30 text-micro tracking-widest uppercase dark:text-cadet-grey-60 leading-[16px] flex items-end"

  return <div style={{ maxWidth: "100%", overflow: "scroll" }} className={cx("mb-3", className)}>
    <div style={{ minWidth: "600px" }}>
      <div style={{ gridTemplateColumns: formatoFinale }} className="w-full grid grid-flow-col">
        <Field className={classiIntestazioni}>{props.intestazioneColonna1}</Field>
        <Field className={classiIntestazioni}>{props.intestazioneColonna2}</Field>
        {props.intestazioneColonna3 && <Field className={classiIntestazioni}>{props.intestazioneColonna3}</Field>}
        {props.intestazioneColonna4 && <Field className={classiIntestazioni}>{props.intestazioneColonna4}</Field>}
        {props.intestazioneColonna5 && <Field className={classiIntestazioni}>{props.intestazioneColonna5}</Field>}
        {props.intestazioneColonna6 && <Field className={classiIntestazioni}>{props.intestazioneColonna6}</Field>}
      </div>
      <div className="border rounded text-small dark:border-dark-border">
        {React.Children.map(children, (child => React.cloneElement(child, { formato: formato })))}
      </div>
    </div>
  </div>
}

export const Riga = ({ children, formato }) => {
  return <div style={{ gridTemplateColumns: formato }} className="w-full grid grid-flow-col even:bg-cadet-grey-70 dark:even:bg-dark-border last:rounded-b">
    {children}
  </div>
}

export const Field = ({ children, className = "" }) => {
  return <div className={cx("p-2", className)}>{children}</div>
}


export default Tabella


function checkFormato(formato) {
  return true // /^(auto|fit-content| |[0-9px]|[1-100%]|[0-9fr]|[0-9](\.[0-9]*)fr)*$/.test(formato)
}