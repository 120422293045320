import React from "react"
import cx from "classnames"
import Link from "./Link"

const GrigliaCards = ({ cols = 3, children }) => {
  return <section>
    <div className={cx("grid gap-3", format[cols])}>
      {children}
    </div>
  </section>
}

export const GrigliaCard = ({ link, icon, children }) => {
  const classes = "px-3 py-2 flex border rounded font-medium dark:border-dark-border"
  const Content = () => <>
    {icon && <img src={icon} alt={children} className="mr-1 h-3 w-3 min-w-3 max-w-3" />}
    {children}
  </>

  return link ? <Link to={link} className={classes}>
    <Content />
  </Link> : <div className={cx(classes, "text-deep-sky-blue-10")}>
    <Content />
  </div>
}
const format = {
  1: "grid-cols-1",
  2: "grid-cols-1 md:grid-cols-2",
  3: "grid-cols-1 md:grid-cols-2 lg:grid-cols-3",
}

export default GrigliaCards