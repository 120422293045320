import React from "react";
import Link from "../../element/Link";

const DatoButton = ({ tipologiaDiLink, ancoraggioInterno, linkInterno, linkEsterno, testo, ...props }) => {
  if (tipologiaDiLink === "ancora") {
    return /^[a-z]+[a-z-]*[a-z]+$/.test(ancoraggioInterno) && testo && <a className="c-button button" href={`#${ancoraggioInterno}`}>{testo}</a>
  }

  if (tipologiaDiLink === "interno") {
    return linkInterno && linkInterno !== null && linkInterno.locale && linkInterno.slug && testo && <Link className="c-button" to={`/${linkInterno.locale}/${linkInterno.slug}`} button>{testo}</Link>
  }

  if (tipologiaDiLink === "esterno") {
    return linkEsterno && testo && <a className="c-button button" href={linkEsterno} target="_blank" rel="noreferrer">{testo}</a>
  }

  return null
}

export default DatoButton