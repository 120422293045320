const plainLibrary = require('datocms-structured-text-to-plain-text');

function extractText(componente) {
  switch (componente.__typename) {
    case "DatoCmsSezioneDiPagina": {
      return plainLibrary.render(componente.contenuto, {
        renderBlock({ record }) {
          return extractText(record)
        },
      })
    }
    case "DatoCmsNotifiche": {
      return plainLibrary.render(componente.content, {
        renderBlock({ record }) {
          return extractText(record)
        },
      })
    }
    case "DatoCmsTabella": {
      return componente.intestazioneColonna1 + " " + componente.intestazioneColonna2 + " " + componente.intestazioneColonna3 + " " + componente.intestazioneColonna4 + " " + componente.intestazioneColonna5 + " " + componente.righe.map(riga => {
        return plainLibrary.render(riga.colonna1, { renderBlock({ record }) { return extractText(record) } }) + " " +
          (riga.colonna2 !== null ? plainLibrary.render(riga.colonna2, { renderBlock({ record }) { return extractText(record) } }) + " " : "") +
          (riga.colonna3 !== null ? plainLibrary.render(riga.colonna3, { renderBlock({ record }) { return extractText(record) } }) + " " : "") +
          (riga.colonna4 !== null ? plainLibrary.render(riga.colonna4, { renderBlock({ record }) { return extractText(record) } }) + " " : "") +
          (riga.colonna5 !== null ? plainLibrary.render(riga.colonna5, { renderBlock({ record }) { return extractText(record) } }) : "")
      }).join(" ");
    }
    case "DatoCmsBloccoADueColonne": {
      return plainLibrary.render(componente.colonna1, { renderBlock({ record }) { return extractText(record) } }) + " " +
        plainLibrary.render(componente.colonna2, { renderBlock({ record }) { return extractText(record) } })
    }
    case "DatoCmsSezioneConTab": {
      return componente.singoliTab.map(tab => plainLibrary.render(tab.contenuto, { renderBlock({ record }) { return extractText(record) } })).join(" ")
    }
    case "DatoCmsGrid": {
      return plainLibrary.render(componente.intestazione, { renderBlock({ record }) { return extractText(record) } }) + " " +
        componente.content2.map(single => plainLibrary.render(single.content3, { renderBlock({ record }) { return extractText(record) } })).join(" ")
    }
  }
}

module.exports = {
  extractText
}