import React from "react";
import ListaConIcone, { Elemento } from "../../element/ListaConIcone";

const DatoListaConIcone = ({ colonne, titolo, lista, statoIniziale }) => {

  return <ListaConIcone cols={colonne} titolo={titolo} stato_iniziale={statoIniziale}>
    {lista.map(icon => {
      const link = icon.linkInterno && icon.linkInterno !== null ? `/${icon.linkInterno.locale}/${icon.linkInterno.slug}` : undefined
      return <Elemento icona={icon.icona.url} link={link}>
        {icon.label}
      </Elemento>
    })}
  </ListaConIcone>
}

export default DatoListaConIcone