import React from "react";
import Tabella, { Field, Riga } from "../../element/Tabella";
import StructuredText from "../fields/StructuredText";

const DatoTabella = ({ formatoColonne, righe, ...props }) => {
  const numeroColonne = colsTotal(formatoColonne)

  return <Tabella formato={formatoColonne} {...props} className="mt-3">
    {righe.map((riga, index) => <Riga key={`tabella-riga-${index}`}>
      <Field><StructuredText data={riga.colonna1} /></Field>
      <Field><StructuredText data={riga.colonna2} /></Field>
      {numeroColonne >= 3 && <Field><StructuredText data={riga.colonna3} /></Field>}
      {numeroColonne >= 4 && <Field><StructuredText data={riga.colonna4} /></Field>}
      {numeroColonne >= 5 && <Field><StructuredText data={riga.colonna5} /></Field>}
      {numeroColonne >= 6 && riga.colonna6.value && <Field><StructuredText data={riga.colonna6} /></Field>}
    </Riga>)}
  </Tabella>
}

export default DatoTabella

function colsTotal(formatoColonne) {
  return formatoColonne.split(" ").filter(value => value !== "" && value).length
}