import React from "react";
import InlineImmagine from "../../element/InlineImmagine";

const DatoInlineImmagine = ({ titoloAlt, immagine, immagineDark, altezzaDesktop, larghezzaDesktop, altezzaMobile, larghezzaMobile, nascondiInMobile, ...props }) => {

  return <InlineImmagine
    titolo_alt={titoloAlt}
    immagine={immagine}
    immagine_dark={immagineDark}
    altezza_desktop={altezzaDesktop}
    altezza_mobile={altezzaMobile}
    larghezza_desktop={larghezzaDesktop}
    larghezza_mobile={larghezzaMobile}
    nascondi_in_mobile={nascondiInMobile}
  />
}

export default DatoInlineImmagine