import React from "react";
import Link from "./Link";

const BoxConCTA = ({ children, cta, link }) => {

  const validCTA = cta && cta !== "" && link && link !== ""

  return <section className="c-box-cta mb-5">
    <div className="p-2 border rounded bg-cadet-grey-70 w-full flex flex-col sm:flex-row dark:text-white dark:bg-dark-search-popup dark:border-dark-border">
      <div className="flex-1">
        {children}
      </div>
      {validCTA && <div className="sm:ml-3 mt-3 sm:mt-0 flex items-center">
        <Link to={link} button className="w-full sm:w-fit">{cta}</Link>
      </div>}
    </div>
  </section>
}

export default BoxConCTA