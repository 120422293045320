import React, { useState } from "react"
import cx from "classnames"
import { CloseFullScreenButton, CopyButton, FullScreenButton } from "./Code"

const TabConCodice = ({ children }) => {
  const tabs = React.Children.map(children, (child => child.props.label))
  const contents = React.Children.map(children, (child => { return { label: child.props.label, plainCode: child.props.plainCode } }))
    .reduce((obj, item) => Object.assign(obj, { [item.label]: item.plainCode }), {})
  const [currentTab, setCurrentTab] = useState(tabs[0]);
  const [fullScreen, setFullScreen] = useState(false);

  return <div className={cx("mb-3", { "fixed h-screen w-screen top-0 left-0 p-3 md:p-5 lg:p-10 bg-white z-[99]": fullScreen })}>
    <div className={cx("rounded border border-cadet-grey-60 bg-white", { "h-full": fullScreen })} >
      <div className="p-[14px] bg-white rounded-tl rounded-tr flex">
        {tabs.map((label, index) => <div key={`tab-con-codice-tab-${index}`} onClick={() => setCurrentTab(label)} onKeyUp={() => setCurrentTab(label)} role="button" tabIndex={index} className={cx("pointer px-1 py-0.25 mr-1 rounded text-small font-medium",
          { "bg-deep-sky-blue-70 text-deep-sky-blue-10": currentTab === label },
          { "text-cadet-grey-30": currentTab !== label })}
          style={{ lineHeight: '24px' }}
        >
          {label}
        </div>)}
        <div className="ml-auto flex">
          <CopyButton content={contents[currentTab]} />
          <div className="mr-1" />
          {!fullScreen && <FullScreenButton callback={() => {
            setFullScreen(true)
            document.getElementsByTagName('html')[0].classList.add("stop-scrolling")
          }} />}
          {fullScreen && <CloseFullScreenButton callback={() => {
            setFullScreen(false)
            document.getElementsByTagName('html')[0].classList.remove("stop-scrolling")
          }} />}
        </div>
      </div>
      <div className="overflow-scroll" style={{ maxHeight: fullScreen ? "calc(100% - 56px)" : "496px" }}>
        {React.Children.map(children, (child => React.cloneElement(child, { isCurrent: currentTab === child.props.label })))}
      </div>
    </div >
  </div>
}

export const TabCodice = ({ isCurrent = false, plainCode, children }) => {
  return <div className={cx({ "hidden": !isCurrent })}>{children}</div>
}

export default TabConCodice