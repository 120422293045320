import React from "react"

const TableOfContent = ({ children }) => {
  return <div id="table-of-content" className="sticky max-h-[calc(100vh-73px)] h-fit hidden xl:block w-24 min-w-tableOfContent top-[73px] ml-5 py-3.5 text-small">
    {children}
  </div>
}

export default TableOfContent

export const Link = ({ to, children }) => <a className={"!text-cadet-grey-30 block mb-1"} href={to}>{children}</a>

export const TableOfContentFromModular = ({ contenuto = [] }) => {
  return contenuto.map(sezione => {
    if (sezione.__typename === "DatoCmsSezioneDiPagina") {
      return <Link key={sezione.linkDiAncoraggioInterno} to={`#${sezione.linkDiAncoraggioInterno}`}>{sezione.titolo}</Link>
    }
    return null;
  })
}