import React from "react";
import { StructuredText as DatoStructuredText, renderNodeRule } from "react-datocms";
import Block from "../renderer/Blocks";
import Links from "../renderer/Links";
import Code from "../../element/Code";

//Questo componente serve per i campi che contengono SOLO i blocchi di codice. 
//È diverso da i blocchi di codice inline
const StructuredText = ({ data }) => <DatoStructuredText
  data={data}
  renderInlineRecord={({ record }) => { }}
  renderLinkToRecord={({ record, children }) => <Links data={record}>{children}</Links>}
  renderBlock={({ record }) => <Block block={record} />}
  customNodeRules={[
    renderNodeRule((node => node.type === "heading"), () => { return null }),
    renderNodeRule(node => node.type === "paragraph", () => { return null }),
    renderNodeRule(node => node.type === "listItem", () => { return null }),
    renderNodeRule(node => node.type === "span", () => { return null }),
    renderNodeRule(node => node.type === "strong", () => { return null }),
    renderNodeRule(node => node.type === "code", ({ node, key }) => {
      return <Code key={key} showLineNumbers={true} language={node.language} className="!bg-cadet-grey-70 rounded-bl rounded-br">{node.code}</Code>;
    })
  ]}
/>


export default StructuredText