import React from "react";
import cx from "classnames"

const Griglia = ({ children, cols = 2 }) => {
  return <section className="mb-5">
    <div className={cx("grid gap-3", format[cols] ? format[cols] : format[2])}>
      {children}
    </div>
  </section>
}

export const Box = ({ children, border }) => {
  return <div className={cx({ "border p-3 rounded dark:border-dark-border": border })}>
    {children}
  </div>
}

const format = {
  1: "grid-cols-1",
  2: "grid-cols-1 md:grid-cols-2",
  3: "grid-cols-1 md:grid-cols-2 lg:grid-cols-3",
}

export default Griglia