import React from "react";
import Link from "./Link";
import cx from "classnames"
import { StaticImage } from "gatsby-plugin-image";
import { useTranslation } from "react-i18next";
import ChevronLeftAzureImage from "../../assets/images/arrows/chevron-double-left-azure.svg"
import ChevronRightAzureImage from "../../assets/images/arrows/chevron-double-right-azure.svg"

const RelatedPages = ({ next, prev }) => {
  const [t] = useTranslation("components_related_page")

  return (next || prev) && <section className={cx("grid grid-cols-1 font-medium md:grid-cols-2 gap-3")}>

    {prev && <Link to={prev.link} className="border p-2 rounded dark:border-dark-border">
      <span className="block text-small text-cadet-grey-10 dark:text-cadet-grey-70">{t("precedente")}</span>
      <div className="flex items-center"><ChevronLeftAzure /><span>{prev.label}</span></div>
    </Link>}

    {next && <Link to={next.link} className={cx("border p-2 rounded text-right dark:border-dark-border", { "md:col-start-2": !prev })}>
      <span className="block text-small text-cadet-grey-10 dark:text-cadet-grey-70">{t("successiva")}</span>
      <div className="flex items-center justify-end"><span>{next.label}</span><ChevronRightAzure /></div>
    </Link>}
  </section>
}

export default RelatedPages

const ChevronRightAzure = () => <img
  src={ChevronRightAzureImage}
  className="ml-0.5"
  alt="next"
  width={16}
  height={16}
/>

const ChevronLeftAzure = () => <img
  src={ChevronLeftAzureImage}
  className="mr-0.5"
  alt="prev"
  width={16}
  height={16}
/>