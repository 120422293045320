import React from "react"
import cx from "classnames"

const DoppiaColonna = ({ colonna1, colonna2, grandezzaColonne, invertiMobile }) => {
  const classes = format[grandezzaColonne] ? format[grandezzaColonne] : format["50-50"]

  return <section className={cx("c-doppia-colonna mb-5 gap-3 grid", classes.container)}>
    <div className={cx(classes.col1, { "order-2 lg:order-1": invertiMobile })}>{colonna1}</div>
    <div className={cx(classes.col2, { "order-1 lg:order-2": invertiMobile })}>{colonna2}</div>
  </section>
}

const format = {
  "50-50": {
    "container": "grid-cols-1 md:grid-cols-2",
    "col1": "",
    "col2": ""
  },
  "1-3-2-3": {
    "container": "grid-cols-1 lg:grid-cols-3",
    "col1": "",
    "col2": "lg:col-span-2"
  },
  "2-3-1-3": {
    "container": "grid-cols-1 lg:grid-cols-3",
    "col1": "lg:col-span-2",
    "col2": ""
  },
  "40-60": {
    "container": "grid-cols-1 md:grid-cols-2 lg:grid-cols-5",
    "col1": "lg:col-span-2",
    "col2": "lg:col-span-3"
  },
  "60-40": {
    "container": "grid-cols-1 md:grid-cols-2 lg:grid-cols-5",
    "col1": "lg:col-span-3",
    "col2": "lg:col-span-2"
  },
}


export default DoppiaColonna