import React from "react";
import cx from "classnames"
import FunzionalitaOk from "../../assets/images/icons/funzionalita-ok.svg"
import FunzionalitaKo from "../../assets/images/icons/funzionalita-ko.svg"

const Funzionalita = ({ children, bordo = true, cols = 1, titolo }) => {
  return <section>
    <div className={cx({ "border dark:border-dark-border p-2 rounded": bordo })}>
      {titolo && titolo !== "" && <div className="font-medium uppercase text-micro leading-[16px] mb-1 tracking-uppercase-title dark:text-white">{titolo}</div>}
      <div className={cx("grid grid-col-1 gap-1", { "md:grid-cols-2": cols === 2 })}>
        {children}
      </div>
    </div>
  </section>
}

export const Funzione = ({ children, attivo = true }) => {
  return <div className="text-small p-0.5 text-cadet-grey-20 dark:text-cadet-grey-50 flex">
    <img src={attivo ? FunzionalitaOk : FunzionalitaKo} className="block mr-1 max-h-2 max-w-2 h-2 w-2 mt-0.5 " alt={attivo ? "attiva" : "non attiva"} />
    {children}
  </div>
}
export default Funzionalita