import React, { useState } from "react"
import cx from "classnames"

const SchedeTab = ({ children }) => {

  //React.Children.forEach(children, (child => React.cloneElement(child, { setParentCurrent: setCurrentRecursive })))}
  const tabs = React.Children.map(children, (child => child.props.label))
  const [currentTab, setCurrentTab] = useState(tabs[0]);

  return <section>
    <div className="flex mb-3 border-b border-cadet-grey-60 dark:border-dark-border">
      {tabs.map((label, index) => <div onClick={() => setCurrentTab(label)} onKeyUp={() => setCurrentTab(label)} role="button" tabIndex={index} className={cx(
        "cursor-pointer py-1.5 px-3 text-small font-medium border-b-2 ", {
        "text-deep-sky-blue-10 border-deep-sky-blue-10": currentTab === label,
        "text-cadet-grey-30 border-white dark:border-cadet-grey-10": currentTab !== label
      })} key={index}>
        {label}
      </div>)}
    </div>
    <div>
      {React.Children.map(children, (child => React.cloneElement(child, { isCurrent: currentTab === child.props.label || tabs.lenght === 1 })))}

    </div>
  </section>
}

export const Tab = ({ isCurrent = false, children }) => {
  return <div className={cx({ "hidden": !isCurrent })}>{children}</div>
}

export default SchedeTab