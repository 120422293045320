import React from "react"
import Link from "./Link"

const Breadcrumb = ({ links = [] }) => {
  const crumbs = links.map((link, index) => {
    return Object.keys(link).map(key => {
      if (link[key]) {
        return <Link to={link[key]} className="!text-cadet-grey-40" key={`breadcrumb-link-${key}`}>{key}</Link>
      } else {
        return <span className="text-cadet-grey-40" key={`breadcrumb-not-link-${key}`}>{key}</span>
      }
    })
  })

  return links && links.length > 0 && crumbs && crumbs.length > 0 && <section className="text-small mb-1" style={{ lineHeight: "24px" }}>
    {crumbs.reduce((prev, succ) => [prev, <span className="mx-0.5 text-cadet-grey-40" key={`breadcrumb-span`}>/</span>, succ])}
  </section>
}

export default Breadcrumb