import React from "react";
import Griglia, { Box } from "../../element/Griglia";
import StructuredText from "../fields/StructuredText";

const DatoGriglia = ({ numeroDiColonne, bordoSuiBox, content2, intestazione }) => {
  return <section className="c-dato-griglia">
    {intestazione && <StructuredText data={intestazione} />}
    <div className="mb-1" />
    <Griglia cols={numeroDiColonne}>
      {content2.map(box => <Box border={bordoSuiBox}>
        <StructuredText data={box.content3} />
      </Box>)}
    </Griglia>
  </section>
}

export default DatoGriglia