import React from "react"
import { components } from "../configuration/Mapping"

const Block = ({ block }) => {
  const Component = components[block.__typename] ? components[block.__typename] : Error
  return <Component {...block} />
}

const Error = ({ __typename }) => {
  if (process.env.GATSBY_ACTIVE_ENV !== "production") {
    return <h2 className="bg-ucla-gold-30 text-deep-sky-blue-20">Questo blocco non esiste (tipo: {__typename})</h2>
  }
}

export default Block