import React from "react";
import BoxConCTA from "../../element/BoxConCTA";
import StructuredText from "../fields/StructuredText";

const DatoBoxConCTA = ({ contenuto, testoCta, linkEsterno, link, linkInterno }) => {
  const ctaLink = link === "interno" ? `/${linkInterno.locale}/${linkInterno.slug}` :
    link === "esterno" ? linkEsterno : undefined

  return <BoxConCTA cta={testoCta} link={ctaLink}>
    <StructuredText data={contenuto} />
  </BoxConCTA>
}

export default DatoBoxConCTA