import React, { useState } from "react";
import cx from "classnames"
import Link from "./Link"
import ChevronDownBlack from "../../assets/images/arrows/chevron-down-black.svg"
import ChevronDownWhite from "../../assets/images/arrows/chevron-down-white.svg"
import ChevronUpBlack from "../../assets/images/arrows/chevron-up-black.svg"
import ChevronUpWhite from "../../assets/images/arrows/chevron-up-white.svg"
import ChevronRightBlack from "../../assets/images/arrows/chevron-right-black.svg"
import ChevronRightGrey from "../../assets/images/arrows/chevron-right-grey.svg"
import ChevronRightBlue from "../../assets/images/arrows/chevron-right-blue.svg"

const ListaConIcone = ({ children, titolo, cols, stato_iniziale = false }) => {
  const [open, setOpen] = useState(!stato_iniziale)

  return <section>
    <div className="border dark:border-dark-border rounded">
      <div className={cx("uppercase text-micro font-medium cursor-pointer flex items-center  tracking-uppercase-title dark:text-white", { "p-2": !open }, { "px-2 pt-2 pb-1.5": open })} onClick={() => setOpen(old => !old)}>
        <img src={open ? ChevronUpBlack : ChevronDownBlack} className="inline-block mr-1 dark:hidden" />
        <img src={open ? ChevronUpWhite : ChevronDownWhite} className="inline-block mr-1 hidden dark:block" />
        {titolo}
      </div>

      {open && <div className={cx("grid grid-cols-1 gap-1 px-1 pb-1", { "md:grid-cols-2": cols === 2 })} >
        {React.Children.map(children, (child => React.cloneElement(child, { open: open })))}
      </div>}

      {!open && <div className="cursor-pointer px-2 pb-2 flex flex-wrap" onClick={() => setOpen(true)}>
        {React.Children.map(children, (child => React.cloneElement(child, { open: open })))}
      </div>}
    </div>
  </section >
}

export const Elemento = ({ children, icona, link, open = true }) => {
  if (open) {
    if (link && link !== null && link !== "") {
      return <Link className={cx("p-1 hover:bg-cadet-grey-70 dark:hover:bg-dark-focus-bg rounded flex !text-cadet-grey-20 dark:!text-cadet-grey-50 hover:!text-deep-sky-blue-10 flex items-center u-hide-img-wrong-hover text-small", { "mb-1": !open })} to={link}>
        <img src={icona} alt={children} className={"max-w-4 max-h-4 h-4 w-4 mr-1"} />
        {children}
        <img alt={"link"} src={ChevronRightBlack} className={"max-w-2 max-h-2 ml-0.5 dark:hidden hover-invisible"} />
        <img alt={"link"} src={ChevronRightBlue} className={"max-w-2 max-h-2 ml-0.5 dark:!hidden hidden hover-visible"} />
        <img alt={"link"} src={ChevronRightGrey} className={"max-w-2 max-h-2 ml-0.5 hidden dark:block"} />
      </Link>
    } else {
      return <div className={cx("p-1 flex items-center text-cadet-grey-20 dark:text-cadet-grey-50 text-small", { "mb-1": !open })}>
        <img src={icona} alt={children} className={"max-w-4 max-h-4 h-4 w-4 mr-1"} />
        {children}
      </div>
    }
  } else {
    return <div className={cx("max-w-4 max-h-4 h-4 w-4 mr-2", { "mb-1": !open })}>
      <img src={icona} alt={children} className={"max-w-4 max-h-4 h-4 w-4 "} />
    </div>
  }

  return null
}

export default ListaConIcone