import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import cx from "classnames"

const DatoImmagine = ({ immagine, immagineFullWidth, immagineDark, immagineDarkFullWidth, immagineMobile, immagineMobileFullWidth, immagineDarkMobile, immagineDarkMobileFullWidth, visualizzazione, testoAlternativo }) => {

  const visibilita = {
    "immagine": [cx({ "dark:hidden": immagineDark, "hidden lg:block": immagineMobile }), immagine, immagineFullWidth],
    "immagineDark": [cx("hidden dark:flex", { "dark:hidden dark:lg:block": immagineMobile }), immagineDark, immagineDarkFullWidth],
    "immagineMobile": [cx("block lg:hidden", { "dark:hidden": immagineDarkMobile }), immagineMobile, immagineMobileFullWidth],
    "immagineDarkMobile": [cx("hidden dark:block lg:hidden dark:lg:hidden"), immagineDarkMobile, immagineDarkMobileFullWidth]
  }

  const immagini = Object.keys(visibilita).map(image => {
    if (visibilita[image][1]) {
      if (visibilita[image][1].format === "svg") {
        return <div className={cx("c-img w-full flex justify-center", visibilita[image][0])}>
          <img src={visibilita[image][1].url} alt={testoAlternativo} style={{ width: visualizzazione === "full-width" ? "100%" : "" }} className=" dark:border-dark-border" />
        </div>
      } else {
        if (visualizzazione === "full-width") {
          return <GatsbyImage className={cx("c-img w-full border dark:border-dark-border", visibilita[image][0])} image={visibilita[image][2].gatsbyImageData} alt={testoAlternativo} />
        }

        if (visualizzazione === "center") {
          return <div className={cx("c-img w-full flex justify-center", visibilita[image][0])}>
            <GatsbyImage image={visibilita[image][1].gatsbyImageData} alt={testoAlternativo} className={"border dark:border-dark-border"} />
          </div>
        }
      }
    }
  })

  return immagini
}

export default DatoImmagine
