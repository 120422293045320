import React from "react"
import { useTranslation } from "react-i18next"
import Notifiche from "../../element/Notifiche"

const DatoPaginaNonDisponibile = () => {
  const [t] = useTranslation("components_unable_page")

  return <Notifiche type={"warning"}>
    <span dangerouslySetInnerHTML={{ "__html": t("html") }} />
  </Notifiche>
}

export default DatoPaginaNonDisponibile