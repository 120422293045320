import React from "react"
import DoppiaColonna from "../../element/DoppiaColonna"
import StructuredText from "../fields/StructuredText"

const DatoDoppiaColonna = ({ colonna1, colonna2, ...props }) => {
  return <DoppiaColonna {...props}
    colonna1={<StructuredText data={colonna1} />}
    colonna2={<StructuredText data={colonna2} />}
  />
}

export default DatoDoppiaColonna