import React from "react";
import { StructuredText as DatoStructuredText, renderNodeRule } from "react-datocms";
import Block from "../renderer/Blocks";
import Links from "../renderer/Links";
import Code from "../../element/Code";
import Link from "../../element/Link";
import InlineRecord from "../renderer/InlineRecord";
import cx from "classnames";

const StructuredText = ({ data, className = "" }) => <DatoStructuredText
  data={data}
  renderInlineRecord={({ record }) => <InlineRecord record={record} />}
  renderLinkToRecord={({ record, children }) => <Links data={record}>{children}</Links>}
  renderBlock={({ record }) => <Block block={record} />}
  customNodeRules={[
    renderNodeRule((node => node.type === "heading"), ({ children, key, ...props }) => {
      const HeadingTag = `h${props.node.level}`;
      return <HeadingTag className={'h-title text-cadet-grey-10 dark:text-white mb-3'} key={key}>{children}</HeadingTag>
    }),
    renderNodeRule(node => node.type === "paragraph", ({ children, key, ...props }) => {
      return <p className={''} key={key}>{children}</p>
    }),
    renderNodeRule(node => node.type === "link", ({ children, key, ...props }) => {
      return <Link key={key} to={props.node.url} target="__blank" underline icon>{children}</Link>
    }),
    renderNodeRule(node => node.type === "listItem", ({ children, key, ...props }) => {
      return <li className="my-1" key={key}>{children}</li>;
    }),
    renderNodeRule(node => node.type === "span" && node.marks && node.marks.includes("code"), ({ children, key, node, ...props }) => {
      const bold = node.marks.includes("strong")
      return <span className={cx("font-jetbrains px-0.5 leading-[22px] border rounded bg-cadet-grey-70 text-small text-cadet-grey-20 dark:bg-dark-code dark:text-cadet-grey-60 tracking-wide dark:border-dark-border", { "font-semibold": bold })} key={key}>{node.value}</span>;
    }),
    renderNodeRule(node => node.type === "strong", ({ children, key, node, ...props }) => {
      return <b className="font-medium" key={key}>{children}</b>;
    }),
    renderNodeRule(node => node.type === "code", ({ node, key, ...props }) => {
      return <Code key={key} language={node.language} className="!bg-cadet-grey-70 rounded border border-cadetgrey-60 dark:!border-dark-border dark:!text-cadet-grey-60 dark:!bg-cadet-grey-10 mb-3" copyButton>{node.code}</Code>;
    })
  ]}
/>


export default StructuredText